import React from 'react'

const NotFoundPage = () => {
  return (
    <div>
      Sorry we didnt find your page
    </div>
  )
}

export default NotFoundPage
